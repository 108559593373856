import React, { useRef } from "react";
// import { Editor as BlogEditot } from '@tinymce/tinymce-react';
// import BundledEditor from "./BundledEditor";
import { Editor } from '@tinymce/tinymce-react';

function TextEditor({ value, onChange }) {
    const editorRef = useRef(null);
    return (
        <>
            {/* <BundledEditor
                apiKey="dnxhjivwtsdxxh69dlrrazkd08pxxf96u30ylx8v1v0mxiub"
                value={value}
                onInit={(_evt, editor) => editorRef.current = editor}
                onEditorChange={(newvalue, editor) => {
                    onChange(newvalue, editor.getContent({ format: "html" }));
                }}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                        'searchreplace', 'table', 'wordcount'
                    ],
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    menubar: 'favs file edit view insert format tools table help',
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor link | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    branding: false,
                    // language: 'en',
                    noneditable_class: 'mceNonEditable',
                }}
            /> */}
            {/* ONlinefitting key */}
            {/* c6f6qhnjgwn7t2oypmfu3vibhkvmdm50btv5u8ykymkuq5a6 */} 

            {/* Current key mukesh */}
            <Editor
                apiKey="dnxhjivwtsdxxh69dlrrazkd08pxxf96u30ylx8v1v0mxiub"
                onInit={(_evt, editor) => (editorRef.current = editor)}
                value={value}
                onEditorChange={(newvalue, editor) => {
                    onChange(newvalue, editor.getContent({ format: "html" }));
                }}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
            />
        </>
    );
}

export default TextEditor;
