import { AiOutlineDashboard, AiOutlineDollarCircle, AiOutlineSetting } from 'react-icons/ai'
import { BsBoxSeam } from 'react-icons/bs'
import { IoIosMan } from 'react-icons/io'
import { VscGraphLine } from 'react-icons/vsc'
export const NAV_LINKS = [
    {
        screen: 'dashboard',
        hasSubMenu: false,
        icon: <AiOutlineDashboard className='w-full h-full' />,
        link: '/dashboard'
    },
    {
        screen: 'sales',
        hasSubMenu: true,
        icon: <AiOutlineDollarCircle className='w-full h-full' />,
        link: '/sales',
        sub_link: [
            {
                screen: 'orders',
                link: '/sales/orders',
            },
            // {
            //     screen: 'invoices',
            //     link: '/sales/invoices',
            // },
            // {
            //     screen: 'returns',
            //     link: '/sales/returns',
            // },
        ]
    },
    {
        screen: 'customers',
        hasSubMenu: true,
        icon: <IoIosMan className='w-full h-full' />,
        link: '/customers',
        sub_link: [
            {
                screen: 'all customers',
                link: '/customers/all-customers',
            },
            {
                screen: 'coupons',
                link: '/customers/coupons',
            },
        ]
    },
    {
        screen: 'catalog',
        hasSubMenu: true,
        icon: <BsBoxSeam className='w-full h-full' />,
        link: '/catalog',
        sub_link: [
            {
                screen: 'products',
                link: '/catalog/products',
            },
            {
                screen: 'categories',
                link: '/catalog/category',
            },
            {
                screen: 'product uploads',
                link: '/catalog/product-uploads',
            },
            {
                screen: 'Quotation List',
                link: '/quotation-list',
            }
        ]
    },
    // {
    //     screen: 'reports',
    //     hasSubMenu: true,
    //     icon: <VscGraphLine className='w-full h-full' />,
    //     link: '/reports',
    //     sub_link: []
    // },
    {
        screen: 'system',
        hasSubMenu: true,
        icon: <AiOutlineSetting className='w-full h-full' />,
        link: '/system',
        sub_link: [
            {
                screen: 'blogs',
                link: '/system/blogs'
            },
            {
                screen: 'Currency',
                link: '/system/currency'
            },
            {
                screen: 'Shipments',
                link: '/system/shipping'
            },
    
        ]
    },
]