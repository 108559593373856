import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from 'react'
import axios from "../apis/axios";
import { TOKEN_KEY } from "../config";
import toast from "react-hot-toast";
let TOKEN = sessionStorage.getItem(TOKEN_KEY);

function useUploadfile({
    refreshUrl = '',
    url = 'product_excel_upload',
    onSuccess,
    onError
}) {
    const queryClient = useQueryClient();

    const setUsers = async (getFormData) => {
        let fd = new FormData();
        let excelFileName = '';

        for (const key in getFormData) {
          if (key === 'excel_file') {
            fd.append(key, getFormData[key]);
          } else if (Array.isArray(getFormData[key])) {
            fd.append(key, JSON.stringify(getFormData[key]));
          } else {
            fd.append(key, getFormData[key]);
          }
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url:`${url}?excel_file=${excelFileName}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '' }
        });
        return response.data;
    };

    const setDataToServer = useMutation(setUsers, {
        // onError: (err) => {
        //     onError?.(err);
        // },
        onError: (err) => {
          // Access and display the API error message
          const errorMessage = err.response?.data?.message || 'File upload failed';
          toast.error(errorMessage);

          // Call optional onError callback
          onError?.(err);
      },
        onSuccess: (data) => {
            queryClient.invalidateQueries(refreshUrl);
            onSuccess?.(data);
            toast.success('File Uploaded')
        }
    });

    return { setDataToServer };
}



export default useUploadfile